var root_path = window.location.protocol + '//' + document.location.hostname + '/admin',
	droppedFiles = null,

GH = GH || {};

GH = {
	
	doLogin: function(){
		
		// var campos = 'Elogin;Esenha;Elembrar';
		var dados = $("#frmLogin").serialize();

		jQuery.ajax({
			type: "POST",
			url: root_path + "/MVC/action/Login.php",
			data: dados,
			success: function(msg){
				var msg = $.trim(msg);
				
				if (msg == 'ok') {
					window.location = root_path;
				} else if (msg == 'invalido') {
					swal({
						title: "Login inválido",
						text: "Os dados inseridos não são válidos!",
						type: "error"
					});
				} else {
					swal({
						title: "Erro",
						text: "Ocorreu um erro! Tente novamente mais tarde.",
						type: "error"
					});
				}

			}
		});
		
	},

	sair: function(){

		window.location = root_path + '/MVC/action/Logof.php';

	},

	alterarPadraoListagem: function (codigo, nome, action){

		swal({
                title: "Alterar",
                text: "Nome:",
                type: "input",
                showCancelButton: true,
                closeOnConfirm: false,
                animation: "slide-from-top",
                showLoaderOnConfirm: true,
                inputPlaceholder: "Nome",
                inputValue: nome
            },
            function(inputValue){
            if (inputValue === false) return false;

            if (inputValue === "") {
                swal.showInputError("Este campo é obrigatório!");
                return false
            }

            var dados = "Ecodigo="+codigo+"&Enome="+inputValue;
            jQuery.ajax({
                type: "POST",
                url: root_path + '/MVC/action/'+action,
                data: dados,
                success: function(msg) {
                    var msg = $.trim(msg);
                    if (msg == 'ok') {
                        document.location.reload();
                    } else {
                        swal({
                            title: "Erro",
                            text: "Ocorreu um erro! Tente novamente mais tarde.",
                            type: "error"
                        });
                    }
                }
            });

            // swal("Alterado com sucesso!");
        });

	},
	
	cadastroPadrao: function (action,lista,form){

		// if(validaForm(form)){
		if(validaForm(form)){

			$('.btn-salvar').text('Salvando...').prop('disabled', true);

			var dados = $("#"+form).serialize();
			jQuery.ajax({
				type: "POST",
				url: '/MVC/action/'+action+'.php',
				data: dados,
				success: function(msg) {
					
					/* TRAZER AQUI O MODAL SOLICITANDO SE DESEJA IR PARA LISTAGEM OU REALIZAR NOVO CADASTRO */

				}
			});

		}

	},

	cadastroRapidoListagem: function (action){
		
		$('.btn-cadastro-rapido').text('Salvando...').prop('disabled', true);
		
		var dados = "Enome=" + $("#Enome").val();

		jQuery.ajax({
			type: "POST",
			url: root_path + '/MVC/action/'+action,
			data: dados,
			success: function(msg) {
				document.location.reload();
			}
		});
		
	},

	alterarArtigo: function(action,lista,form) {

		// $("#"+form).closest('.btn-add').text('Salvando...').prop('disabled', true);

        /*SirTrevor.SKIP_VALIDATION = true;
        SirTrevor.onBeforeSubmit();

        var json = SirTrevor.getInstance().store.retrieve();

        SirTrevor.SKIP_VALIDATION = false;*/

        // $('#Eoutput').text($('.js-st-instance').val());

        // console.log(danteEditor.getPosition);
        // console.log(danteEditor.getContent());

        var formData = new FormData($("#"+form).get(0));

		if (droppedFiles) {
			formData.set("Ecover", droppedFiles[0]);
		}

		danteEditor.save_behavior.handleStore();
		formData.append("Eartigo", danteEditor.getContent());

		jQuery.ajax({
			type: "POST",
			url: root_path + '/MVC/action/'+action,
			data: formData,
			cache: false,
			processData: false,
			contentType: false,
			success: function(msg) {

				var msg = $.trim(msg);

				if (msg == "ok") {
					window.location.href = root_path + "/"+lista+"?q=success";
				} else {
					window.location.href = root_path + "/"+lista+"?q=error";
				}

			}
		});
	},
	
	alterarPadrao: function (action,lista,form){

		// if(validaForm(form)){

			// $('.btn-salvar').text('Salvando...').prop('disabled', true);

			var dados = $("#"+form).serialize();
			jQuery.ajax({
				type: "POST",
				url: root_path + '/MVC/action/'+action,
				data: dados,
				success: function(msg) {
					var msg = $.trim(msg);
					if (msg == 'ok') {
						// document.location.reload();

						var msg = $.trim(msg);

						if (msg == "ok") {
							window.location.href = root_path + "/"+lista+"?q=success";
						} else {
							window.location.href = root_path + "/"+lista+"?q=error";
						}

					}
				}
			});

		// }

	},
	
	excluirPadrao: function(action,codigo){

		swal({
			title: "Confirma a exclusão?",
			text: "Esse arquivo não poderá ser recuperado!",
			type: "warning",
			showCancelButton: true,
			cancelButtonText: "Cancelar",
			// confirmButtonColor: "#DD6B55",
			confirmButtonText: "OK",
			closeOnConfirm: true
		},
		function(){

			var dados = 'codigos='+codigo;

			jQuery.ajax({
				type: "POST",
				url: root_path + '/MVC/action/'+action,
				data: dados,
				success: function(msg) {
					var msg = $.trim(msg);
					if (msg == 'ok') {
						$("#linha-"+codigo).fadeOut();
						GH.toastSuccess("Registro excluído com sucesso!");
					} else if (msg == 'off') {
						Redirect('../offline.php');
					} else if (msg == 'erro') {
						swal({
							title: "Erro",
							text: "Ocorreu um erro! Tente novamente mais tarde.",
							type: "error"
						});
					} else if (msg == 'banco') {
						swal({
							title: "Erro",
							text: "Este cadastrado nao pode ser excluido por estar vinculado a outros cadastros.",
							type: "error"
						});
					}
				}
			});

		});

	},
	
	addParameterUrl: function(url, param, value){

		var hash       = {};
	    var parser     = document.createElement('a');

	    parser.href    = url;

	    var parameters = parser.search.split(/\?|&/);

	    for(var i=0; i < parameters.length; i++) {
	        if(!parameters[i])
	            continue;

	        var ary      = parameters[i].split('=');
	        hash[ary[0]] = ary[1];
	    }

	    hash[param] = value;

	    var list = [];  
	    Object.keys(hash).forEach(function (key) {
	        list.push(key + '=' + hash[key]);
	    });

	    parser.search = '?' + list.join('&');
	    return parser.href;

	},

	filtroPadraoPagina: function(pagina,filtro,valor){

		location.href = GH.addParameterUrl(location.href, filtro, valor);
		
	},

	filtroPadraoParaDatas: function(pagina){

		var data_inicial = document.getElementById('Edata_inicial').value;

		var data_final = document.getElementById('Edata_inicial').value;

		location.href = GH.addParameterUrl(location.href, filtro, valor);

	},

	isExiste: function(cpf){

		if(cpf != ''){

			var dados = 'cpf='+cpf;
			jQuery.ajax({
				type: "POST",
				url: '/MVC/action/VerificarUsuarioCpf.php',
				data: dados,
				success: function(msg) {
					var msg = $.trim(msg);
					if (msg == 'true'){
						alert('Usuário possui cadastrado.');
						document.getElementById("Ecpf").value = '';
						document.getElementById("Ecpf").focus();
					}
					else
					if (msg == 'erro'){
						alert ('Ocorreu um erro ao efetuar a consulta do CPF !');
					}
				}
			});

		}

	},

	ordenaColuna: function(coluna){

		alert(location.host);

		location.href = GH.addParameterUrl(location.href, 'coluna', coluna);

	},

	addColunaArrow: function(coluna) {
		// alert(coluna);
		$("#cl-"+coluna).text(coluna+' <i class="ti-angle-down pull-right"></i>');
	},

	toggleMenu: function() {
		$(".sidebar").toggleClass("closed");
		// $(".top-left-part").toggleClass("closed");
		$("#page-wrapper").toggleClass("submenu-closed");
	},

	loginGetUserPic: function(login) {
		var dados = 'login='+login;
		jQuery.ajax({
			type: "POST",
			url: root_path + '/MVC/action/getUserPic.php',
			data: dados,
			beforeSend: function() {
				$("#user-pic").addClass('img-blur');
			},
			success: function(msg) {
				var msg = $.trim(msg);
				if (msg == 'erro'){
					swal({
						title: "Login inválido",
						text: "Nenhum usuário encontrado!",
						type: "error"
					});
					$("#user-pic").removeClass('img-blur');
					// document.getElementById("Elogin").focus();
				} else {
					$("#user-pic").removeClass('img-blur').css("background-image", "url('uploads/images/user/thumb/"+msg+"')");
				}
			}
		});
	},

	recuperarSenha: function() {
		swal({
				title: "Recuperar Senha",
				// text: "E-mail:",
				type: "input",
				showCancelButton: true,
				cancelButtonText: "Cancelar",
				closeOnConfirm: false,
				animation: "slide-from-top",
				showLoaderOnConfirm: true,
				inputPlaceholder: "Digite seu e-mail"
			},
			function(inputValue){
			if (inputValue === false) return false;

			if (inputValue === "") {
				swal.showInputError("Este campo é obrigatório!");
				return false
			}

			var dados = "Eemail="+inputValue;
			jQuery.ajax({
				type: "POST",
				url: root_path + '/MVC/action/RecuperarSenha.php',
				data: dados,
				success: function(msg) {
					var msg = $.trim(msg);
					if (msg == 'ok') {
						swal("Uma nova senha foi enviada para o seu e-mail!");
					} else if (msg == "invalido") {	
						swal("Nenhum usuário encontrado!");
					} else {
						swal({
							title: "Erro",
							text: "Ocorreu um erro! Tente novamente mais tarde.",
							type: "error"
						});
					}
				}
			});

		});
	},

	toastSuccess: function(msg) {
		$.toast({
			heading: 'Sucesso',
			text: msg,
			showHideTransition: 'slide',
			icon: 'success',
			position: 'top-right'
		})
	},

	toastError: function(msg) {
		$.toast({
			heading: 'Erro',
			text: msg,
			showHideTransition: 'slide',
			icon: 'error',
			position: 'top-right'
		})
	},

	/* DROPZONE FUNCTIONS */

	dzActions: {

		tornarCapa: function(codigo, img, nome) {
			if (codigo == null || !GH.isNumber(codigo) || codigo == "") {
				return function() {
					return GH.toastError("Ocorreu um erro! Tente novamente mais tarde.");
				}
			} else {

				var imagem = "imagem"+img+codigo;

				$('.dz-capa').removeClass('dz-capa');

				if (nome == null) {
					var img_src = $("#"+imagem).find("img").prop("src"),
						str = img_src.split("/"),
						img_name = str.pop();
				} else {
					var img_name = nome;
				}

				document.getElementById("Ecapa").value = img_name;
				document.getElementById(imagem).classList.add('dz-capa');

				return GH.toastSuccess("Capa selecionada com sucesso! ");

			}
		},

		editarLegenda: function(codigo, img, action) {

			if (codigo == null || !GH.isNumber(codigo) || codigo == "") {
				return function() {
					return GH.toastError("Ocorreu um erro! Tente novamente mais tarde.");
				}
			} else {

				legenda = document.getElementById('legenda'+img+codigo).value;

				swal({
					title: "Editar Legenda",
					// text: "E-mail:",
					type: "input",
					showCancelButton: true,
					cancelButtonText: "Cancelar",
					closeOnConfirm: true,
					animation: "slide-from-top",
					showLoaderOnConfirm: true,
					inputPlaceholder: "Digite uma legenda",
					inputValue: legenda,
				},
				function(inputValue){
					if (inputValue === false) return false;					

					/*if (inputValue === "") {
						swal.showInputError("Este campo é obrigatório!");
						return false
					}*/

					var dados = "Ecodigo="+codigo+"&Elegenda="+inputValue;
					jQuery.ajax({
						type: "POST",
						url: root_path + '/MVC/action/'+action,
						data: dados,
						success: function(msg) {
							var msg = $.trim(msg);
							if (msg == 'ok') {
								// document.getElementById('legenda'+img+codigo).value = inputValue;
								document.getElementById('legenda'+img+codigo).value = inputValue;
								return GH.toastSuccess("Legenda editada com sucesso!");
							} else {
								return GH.toastError("Ocorreu um erro e a legenda não foi editada!");
							}
						}
					});

				});
			}

		},

		excluir: function(codigo, img, action) {

			if (codigo == null || !GH.isNumber(codigo) || codigo == "") {
				return function() {
					return GH.toastError("Ocorreu um erro! Tente novamente mais tarde.");
				}
			} else {

				var imagem = "imagem"+img+codigo;
				var dados = 'codigo='+codigo;

				swal({
					title: "Confirma a exclusão?",
					text: "Esse arquivo não poderá ser recuperado!",
					type: "warning",
					showCancelButton: true,
					cancelButtonText: "Cancelar",
					// confirmButtonColor: "#DD6B55",
					confirmButtonText: "OK",
					closeOnConfirm: true
				},
				function(){
					jQuery.ajax({
						type: "POST",
						url: root_path + '/MVC/action/'+action,
						data: dados,
						success: function(msg) {
							var msg = $.trim(msg);
							if (msg == 'ok'){
								
								$('[name="EdpImage[]"').each(function(index) {
									if ($(this).val() == codigo) $(this).remove();
								});

								// if (codigo == 0) { imagem = "imagemAlbum"; }
								document.getElementById(imagem).style.display="none";

								return GH.toastSuccess("Excluído com sucesso!");

								/*var count = document.getElementById('Ecount');
								count.value = parseInt(count.value) - 1;

								if (count.value == 0) {
									document.getElementById('upload').classList.remove('dz-started');
								}*/

							} else {
								return GH.toastError("Ocorreu um erro e a imagem não foi deletada!");
							}
							
						}
					});
				});

			}

		},

	},

	isNumber: function (n) {
		return !isNaN(parseFloat(n)) && isFinite(n);
	},

}

$(document).ready(function() {
	// tooltip init
	$('[data-toggle="tooltip"]').tooltip();

	// slimscroll init
	$(".slimscrollsidebar").slimScroll({
		height: "100%",
		position: "left",
		size: "6px",
		color: "rgba(0,0,0,0.5)",
	});

	// submenu
	$("#side-menu").children("li").on("click", function(e) {
		// e.preventDefault();
		$(this).children("ul").toggleClass('collapse collapse.in');
	});

	// galeria
	$(".label-galeria").on("click", function(e) {
		$(".form-galeria").toggleClass('open');
	});

	// sweetalert
	swal.setDefaults({ confirmButtonColor: '#2cabe3' });

	// form actions
	$(".form-padrao").validator().on("submit", function (e) {
	    if (e.isDefaultPrevented()) {
	    } else {
	        e.preventDefault();

	        var fn = $(this).data("fp-function"),
	        	// action = $(this).data("fp-action"),
	        	action = $(this).attr("action"),
	        	form = $(this).attr("id"),
	        	lista = $(this).data("fp-lista");

	        if (fn == "padrao") {
	        	GH.alterarPadrao(action,lista,form);
	        } else if (fn == "artigo") {
	        	GH.alterarArtigo(action,lista,form);
	        } else if (fn == "login") {
	        	GH.doLogin();
	        }else if (fn == "cadastro-rapido") {
	        	GH.cadastroRapidoListagem(action);
	        }
	    }
	});
});